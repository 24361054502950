import React from 'react'

export default function About() {
  return (
    <section className="sections about" id="about">
      <div className="container">
        <h1 className="section-title">About</h1>
        <div className="row">
          <p className="lead">계속해서 쌓이기만 하는 기업 보유 데이터, 미디어나비의 AI 솔루션은 다양한 분야로의 사업화와 생산성 향상 등 새로운 가치창출을 가능하게 합니다. 수집한 데이터 분석을 통해 공공보안 및 재난예방이 가능하며, 유동인구 분석을 통해 관광, 축제, 행사 등의 효율적인 기획과 안전사고 예방이 가능합니다. 비전 AI 기술을 활용한 이미지 자동인식 및 분석, AI 생성모델 솔루션을 도입하여 기업의 사업화 비용을 절감할 수 있습니다. 미디어나비는 누구나 기술 없이도 인공지능을 누릴 수 있는 세상을 지향합니다.</p>
          <h3 className="col-1-title">n<span style = {{color:'red'}}>A</span>v<span style = {{color:'red'}}>I</span>-Lang<sup>TM</sup></h3>          
          <p className="col-2-text"><strong>나비랑<sup>TM</sup> n<span style = {{color:'red'}}>A</span>v<span style = {{color:'red'}}>I</span>-Lang<sup>TM</sup> - Medianavi Language AI Solution</strong><br></br><br></br>사람과 사람 사이에도 가끔 오해가 생기고 잘못 전달되는 언어. 이 복잡한 사고의 결정체인 자연어를 기계에게 이해시키기 위한 방법들을 일컬어 자연어 처리(Natural Language Processing), NLP라고 부릅니다. 인간이 컴퓨터에게 무엇인가의 결과물을 얻기 위한 입력방식으로 모든 인간이 자연스럽게 체득하고 있는 이 자연어로 의사소통이 가능해지는 것. 이것이 곧 인공지능이라고 말해도 무방할만큼 인공지능에서의 핵심은 NLP라고 볼 수 있습니다. 한국언론진흥재단의 인공지능 언어모델 ‘KPF-BERT’ 개발 총괄 수행이력을 바탕으로 뉴스 기사 본문 요약, 뉴스 기사 클러스터링을 포함한 텍스트 기반 자연어처리  AI 솔루션을 제공합니다.</p>
          <h3 className="col-1-title">n<span style = {{color:'red'}}>A</span>v<span style = {{color:'red'}}>I</span>-An<sup>TM</sup></h3>   
          <p className="col-2-text"><strong>나비안<sup>TM</sup> n<span style = {{color:'red'}}>A</span>v<span style = {{color:'red'}}>I</span>-An<sup>TM</sup> - Medianavi Analysis AI Solution</strong><br></br><br></br>기업 보유 데이터의 수집, 가공, 분석을 통한 AI 솔루션 제공으로 새로운 가치를 창출합니다. 데이터 아키텍처를 구축하여 고객의 니즈에 맞게 데이터를 추출하고 가공하며, 적절한 BI 도구를 활용하여 여러 가지 사업 지표를 시각화합니다. IoT(사물인터넷) 센서 데이터 분석으로 공공보안, 질서유지, 사고예방, 관광 분야에 활용할 수 있습니다. 노후설비의 장애 및 고장 데이터 분석을 통한 고장예측 인공지능 모델 개발로 안전사고 예방 및 최적의 설비 교체시기를 판단합니다. 저장창고의 비전 센서 데이터 분석을 통한 자원절감, 고객 데이터 분석 솔루션으로 신사업 확장 및 마케팅 도구로 사용합니다. 생산자-소비자 연결 상품 자동매칭 서비스 및 빅데이터 분석 고도화 모델링 등 데이터 분석을 기반으로 한 모든 합리적인 의사결정을 돕습니다.</p>
          <h3 className="col-1-title">n<span style = {{color:'red'}}>A</span>v<span style = {{color:'red'}}>I</span>-Sion<sup>TM</sup></h3>   
          <p className="col-2-text"><strong>나비전<sup>TM</sup> n<span style = {{color:'red'}}>A</span>v<span style = {{color:'red'}}>I</span>-Sion<sup>TM</sup> - Medianavi Vision AI Solution</strong><br></br><br></br>이미지 자동인식 및 분석, 이미지 AI 생성모델 구축을 통해 다양한 산업군의 사업화에 활용할 수 있습니다. 비전 AI 분야에서 높은 성능을 보여주는 스테이블 디퓨전 기술을 적용한 파인튜닝 방식으로 이미지 자동인식 및 분석모델 솔루션을 제공합니다. 디퓨전 이미지 생성모델, 버추얼 휴먼 생성모델, 메타버스 체험형 VR 서비스 생성모델, 스포츠 가상훈련을 위한 3D 생성모델, 2D 동영상에서 3D 자동 생성모델에 유연하게 적용 가능한 비전 AI 솔루션으로 확장해 나가고 있습니다.</p>
          <h3 className="col-1-title">시스템 컨설팅</h3>   
          <p className="col-2-text">AI 솔루션 도입시 기업들이 가장 필요로하는 시스템 컨설팅을 제공합니다. 고객사의 니즈에 맞춰 최적의 요구분석 및 관리 체계 구축을 목표로 전문 솔루션 기반 체계 공학 컨설팅을 오랜 기간 축적된 노하우를 바탕으로 지원합니다. 문서로 요구사항을 관리하여 발생하는 휴먼 에러 및 오류를 해결하고, 관리된 요구사항을 정리하여 프로젝트 의사결정을 지원하는 컨설팅 및 교육을 진행하고 있습니다. DB 서버 구축, 시스템 아키텍처 설계와 시스템 컨설팅 솔루션까지 함께 제공하여 고객사의 경영 효율화를 제고하기 위한 방안을 함께 모색합니다.</p>
        </div>
      </div>
    </section>
  )
}
