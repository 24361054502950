import React from "react";

function Works() {
  const worksReps = [
    {
      name: '정보통신산업진흥원',
      content: ['AI바우처 지원사업 - AI 솔루션 공급기업 지정'],
      thumbnail: './img/works/works-rep5.png',
      detail: 'https://blog.medianavi.kr/nipa',
    },
    {
      name: '한국데이터산업진흥원',
      content: ['데이터바우처 지원사업 - AI 가공 부문 공급기업 지정'],
      thumbnail: './img/works/works-rep4.png',
      detail: 'https://blog.medianavi.kr/kdata',
    },
    {
      name: '한국언론진흥재단',
      content: ['언론사를 위한 언어정보자원 개발',
                '뉴스알고 개발 및 유지보수',
                '디지털 뉴스 유통 구조 개선 포럼 논의용 임시 사이트 개발',
                '대규모 언어모델 활용 시범 서비스 개발'],
      thumbnail: './img/works/works-rep1.png',
      detail: 'https://blog.medianavi.kr/kpf',
    },
    {
      name: '토이스미스',
      content: ['백엔드 서버 개발',
                '운영 컨설팅'],
      thumbnail: './img/works/works-rep2.png',
      detail: 'https://blog.medianavi.kr/toysmyth',
    },
    {
      name: '서울마리나',
      content: ['웹사이트 리뉴얼 및 유지보수'],
      thumbnail: './img/works/works-rep3.png',
      detail: 'https://blog.medianavi.kr/seoulmarina',
    },
  ];
  const worksHistory = [
    {
      date: "2023.12 ~ 현재",
      content: "(사)한국인공지능협회 회원사 등록",
      detail : "https://blog.medianavi.kr/koraia/"
    },
    {
      date: "2023.10 ~ 2024.03",
      content: "코드넛, 중∙고등학교 교과서 데이터 가공작업 수행 완료",
      detail : "https://blog.medianavi.kr/codnut/"
    },
    {
      date: "2023.08 ~ 2023.12",
      content: "에이앤에이, 스마트 RPC 실시간 재고관리 시스템 구축사업 수행 완료",
      detail : "https://blog.medianavi.kr/ana/"
    },
    {
      date: "2023.06 ~ 2023.11",
      content: "2023년도 데이터바우처 지원사업 AI가공 프로젝트 2건 수행 완료",
      detail : "https://blog.medianavi.kr/kdata/"
    },
    {
      date: "2023.08 ~ 2023.10",
      content: "한국언론진흥재단, 대규모 언어모델 활용 시범 서비스 개발",
      detail : "https://blog.medianavi.kr/kpf/"
    },
    {
      date: "2023.01 ~ 현재",
      content: "정보통신산업진흥원(NIPA), AI바우처 지원사업 AI 솔루션 공급기업 지정",
      detail : "https://blog.medianavi.kr/nipa"
    },
    {
      date: "2022.11 ~ 현재",
      content: "한국데이터산업진흥원, 데이터바우처 지원사업 AI가공 부문 공급기업 지정",
      detail : "https://blog.medianavi.kr/2022-12-21-datavoucher-service"
    },
    {
      date: "2022.09 ~ 2022.11",
      content: "한국언론진흥재단, 디지털 뉴스 유통 구조 개선 포럼 논의용 임시 사이트 개발",
      detail : "http://smartnews.newsalgo.or.kr"
    }, 
    {
      date: "2022.07 ~ 현재",
      content: "기업신용평가 및 기술평가 우수등급 인증",
      detail : "https://blog.medianavi.kr/2022-12-29-certificate-of-technical-achievement"
    }, 
    {
      date: "2022.03 ~ 2022.12", 
      content: "철도 선로전환기 및 궤도회로 고장예측 인공지능 모델 개발 프로젝트 수행",
      detail : "https://blog.medianavi.kr/2023-04-04-PHM/"
    },
    {
      date: "2022.02 ~ 2023.12", 
      content: "한국언론진흥재단, 2022년~2023년 뉴스알고 유지보수 개발",
      detail : "https://blog.medianavi.kr/2022-10-27-review-newsalgo"
    },
    {
      date: "2021.02 ~ 현재",
      content: "토이스미스, 백엔드 서버 개발 및 운영 컨설팅",
      detail : ""
    },
    {
      date: "2021.07 ~ 2021.12",
      content: "한국언론진흥재단, 언론사를 위한 언어정보자원 개발",
      detail : "https://blog.medianavi.kr/2022-01-21-history-of-ai"
    },
    {
      date: "2020.09 ~ 2020.12",
      content: "폰노이만, 올실버 프로토타입 앱 개발",
      detail : ""
    },
    {
      date: "2020.06 ~ 2022.08",
      content: "서울마리나, 웹페이지 리뉴얼 및 유지 보수",
      detail : "http://www.seoul-marina.com"
    },
  ];
  return (
    <>
    <section className="sections works" id="works">
      <div className="container">
        <h1 className="section-title no-sticky">Works</h1>
      </div>
      <div className="container">
        <ul className="row works-rep">
          {worksReps.map((item, index) => {
            return (
              <li key={index} className="col works-rep--item">
                <a href={item.detail} rel="noreferrer" target="_blank">
                  <div className="works-rep--thumbnail" style={{backgroundImage: `url(${item.thumbnail})`}}></div>
                  <h5>{item.name}</h5>
                  {/* <ul className="works-rep-item--content">
                  {item.content.map((content) => {
                    return <li key={content}>{content}</li>
                  })}
                  </ul> */}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="container">
        <table className="works-history">
          <thead>
            <tr>
              <th>Date</th>
              <th>History</th>
            </tr>
          </thead>
          <tbody>
            {worksHistory.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.date}</td>                                                                        
                  {
                    item.detail !== "" &&                
                    //221209 funny :  a 태그가 td 태그를 감싸면 제대로 안나오는 문제가 있다. td가 a 태그 감싸도록 한다.                       
                    <td>
                      <div className="works_history_link">
                        <a href={item.detail} rel="noreferrer" target="_blank">               
                          {item.content}                              
                        </a>            
                      </div>
                    </td>
                  }
                  {
                    item.detail === "" &&                                                           
                    <td>{item.content}</td>                                                  
                  }                  
                </tr>
              )
            })}
          </tbody>
        </table>
        {/* <div className="works-footer">
          <button className="btn-more centering">더 보기</button>
        </div> */}
      </div>
    </section>
    </>
  )
}

export default Works